<template>
  <div id="app" :class="$route.meta.safeBottom ? 'safe-area-inset-bottom' : ''">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>
<script>
import { getUrlParam } from "@/assets/utils/common";
import { deviceChannel } from "@/util/util";
import { getStorageItem, setStorageItem, delStorageItem } from "@/cookie";

export default {
  created() {
    // 测试环境打开 调试 || process.env.NODE_ENV == 'development'
    // console.log(process.env.NODE_ENV);
    // console.log(process.env.ENV, process.env.NODE_ENV);
    if (process.env.NODE_ENV == "sit"||process.env.NODE_ENV == "uat") {
      this.openVscode();
    }
    // this.openVscode()
    let channelCode = getUrlParam("code");
    let token = getUrlParam("token");
    // let channelCode = this.$route.query.code
    if (channelCode) {
      window.channelCode = channelCode;
      // document.cookie = `JSESSIONID=${res.data.data.sessionId};Secure;SameSite=None`
      setStorageItem("channelCode", channelCode);
    } else {
      // window.channelCode = channelCode
      // delStorageItem('channelCode')
    }
    if (token) {
      setStorageItem("hywToken", token);
      window.myhywToken = token;
    } else {
      // window.myhywToken = token
      // delStorageItem('hywToken')
    }

    // 大会员体系头部 显示隐藏  //1、掌上海银APP、2-财富小程序、3-健康小程序、4-海银基金
    const _code = channelCode || getStorageItem("channelCode");
    if (["1","7"].indexOf(_code) > -1) {
      this.$store.commit("HEADER/SET_SHOW", true);
    } else if (["2", "3", "4"].indexOf(_code) > -1) {
      this.$store.commit("HEADER/SET_SHOW", false);
    } else if (deviceChannel() == "1") {
      // 微信浏览器
      this.$store.commit("HEADER/SET_SHOW", false);
    } else {
      // 默认
      this.$store.commit("HEADER/SET_SHOW", true);
    }
    this.$store.commit("SET_CHANNEL_CODE", _code);
  },
  mounted() {
    setStorageItem("window.location.href", window.location.href);
    console.log('window.location.href', window.location.href);
    let channelCode = getUrlParam("code");
    const _code = channelCode || getStorageItem("channelCode");
    if (["1","7"].indexOf(_code) > -1) {
      this.$store.commit("HEADER/SET_SHOW", true);
    } else if (["2", "3", "4"].indexOf(_code) > -1) {
      this.$store.commit("HEADER/SET_SHOW", false);
    } else if (deviceChannel() == "1") {
      // 微信浏览器
      this.$store.commit("HEADER/SET_SHOW", false);
    } else {
      // 默认
      this.$store.commit("HEADER/SET_SHOW", true);
    }
    this.$store.commit("SET_CHANNEL_CODE", _code);

    // 分享页面
    // setStorageItem('MEMBER_SHARE', {share: this.$route.query.share == 'true'});
  },
  methods: {
    openVscode() {
      var script = document.createElement("script");
      script.src = "https://cdn.bootcss.com/vConsole/3.3.0/vconsole.min.js";
      script.onload = function () {
        window.vonsole = new VConsole();
        // console.log(process.env);
      };
      document.body.appendChild(script);
    },
    // leaveH5() {
    //   var u = navigator.userAgent;
    //   var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
    //   var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    //   if (isAndroid) { //安卓
    //     if (window.addEventListener) {
    //       document.addEventListener("visibilitychange", function () { //部分手机可以获取到
    //         // do something
    //         if (document.hidden) {
    //           setStatusBarStyle()
    //         }
    //       })
    //     } else if (window.attachEvent) {
    //       // 主要是为了兼容老的IE
    //       window.attachEvent('onpagehide', function () {
    //         setStatusBarStyle()
    //       })
    //     } else {
    //       window.onbeforeunload = function () {
    //         setStatusBarStyle()
    //       }
    //     }
    //   }
    //   else { //苹果
    //     window.addEventListener("pagehide", function (e) {
    //       setStatusBarStyle()
    //     }, false);
    //   }
    // }
  },
};
</script>
<style lang="scss">
html,
body {
  height: 100%;
  background-color: #fff;
}

#app {
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: auto;
  left: 50%;
  top: 0;
  max-width: 750PX;
  transform: translateX(-50%);
  /* z-index:2100; */
  background-color: #fff;
  box-sizing: border-box;
  font-family: PingFangSC-Medium, "Microsoft YaHei", "Heiti SC", Helvetica,
    Arial, "Hiragino Sans GB", "WenQuanYi Micro Hei", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#app.safe-area-inset-bottom {
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}

/* #app.safe-area-inset-bottom {
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);

} */

/* .appContView {
  height: 100%;
  height: 100%;
  overflow-y: auto;
  position: relative;
} */

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
