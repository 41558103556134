/*
 * @Des: 
 * @version: 神策 埋点需求
 * @Author: liufeifei
 * @Date: 2023-08-08 13:36:05
 * @LastEditors: liufeifei 1076747468@qq.com
 * @LastEditTime: 2023-08-30 12:19:16
 */

import { getUrlParam } from "@/assets/utils/common";
var sensors = require('sa-sdk-javascript');
import { getStorageItem } from "@/cookie";



const sensorsInit = () => {

  const hash = window.location.hash;
  // 历史订单 过滤 myOrderDetail myOrderEntry  myOrderLogistics
  const _routes = ['#/wechatPhone', '#/verityCode', '#/failureCallback', '#/customerRule', '#/myOrderDetail', '#/myOrderEntry', '#/myOrderLogistics', '#/loading'];
  if (_routes.some(_ => hash.indexOf(_) > -1)) {
    return false;
  }
  if (process.env.NODE_ENV == 'development') {
    return sensors;
  }
  let code = getUrlParam("code");
  let token = getUrlParam("token");
  let unionId = getUrlParam('unionId');
  // return false;
  // 1、掌上海银APP、2-财富小程序、3-健康小程序、4-海银基金
  const production_name = {
    1: '掌上海银APP', 2: '海银财富小程序', 3: '海银健康管家小程序', 4: '海银基金小程序', 7: '掌上海银APP家办入会', 'HYCF': '掌上海银APP',
  };
  let _production_name = production_name[code];

  sensors.init({
    server_url: process.env.VUE_APP_SENSORS_SERVER_URL, // 数据接收地址  测试
    app_js_bridge: true,
    is_track_single_page: true, // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
    use_client_time: true,
    send_type: 'beacon',
    show_log: process.env.NODE_ENV != 'production',
    heatmap: {
      //是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
      clickmap: 'default',
      //是否开启触达图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
      scroll_notice_map: 'default'
    }
  });

  // switch (code) {
  //   case '1':

  //     break;
  //   case '2':
  //   case '3': // 大会员
  //     // 多用户 ID 关联 unionidID
  //     sensors.bind("$identity_mp_unionid", unionId)
  //     break;
  // }
  // 积分兑换 健康小程序 进入 绑定
  if ((!code || code == 'HYJK') && unionId) {
    _production_name = '海银健康管家小程序';
  }

  // 小程序 绑定 unionId
  if (unionId) {
    sensors.bind("$identity_mp_unionid", unionId);
  }

  sensors.registerPage({
    current_url: location.href,
    referrer: document.referrer,
    platform_type: 'H5',
    user_type: '普通用户',
    production_name: _production_name,
    // phone_number_encrypted:'',
    // identity_cf_crm_id:'',
    // identity_jk_crm_id:'',
    cookie_id: sensors.getPresetProperties().identities.$identity_cookie_id,
  });
  // 再执行全埋点 autoTrack 其他操作
  sensors.quick('autoTrack'); //用于采集 $pageview 事件。

  return sensors;
}

export default sensorsInit;

// 注册全局变量 source: 来源
export const registerPage = (user = {}, source = '') => {
  if (process.env.NODE_ENV == 'development') {
    return false;
  }
  // console.log('user',user);
  let code = getUrlParam("code");
  let typeText = '个人户'
  if (user.type == 2) {
    typeText = '机构户'
  }
  const obj = {
    phone_number_encrypted: user.mobileDecrypt + typeText,
  };
  if (code == 3 || source == 'HYJK') { // 健康
    obj.identity_jk_crm_id = user.healthCrmId;
  } else {
    obj.identity_cf_crm_id = user.crmId;
  }
  // if (user.healthCrmId) { // 健康
  //   obj.identity_jk_crm_id = user.healthCrmId;
  // } 
  // if(user.crmId) { // 财富
  //   obj.identity_cf_crm_id = user.crmId;
  // }
  console.log('神策埋点数据', obj);
  sensors.registerPage(obj);

}



/* 自定义埋点
sensors.track('BuyProduct', {
  ProductName: "MacBook Pro", 
  ProductPrice: 123.45, 
  IsAddedToFav: false,
});
*/

export const addSensorsTrack = (properties = {}, event_name = 'C_HYW_BtnClick') => {
  if (!event_name) return;
  //"channel": "1", // 1、掌上海银APP、2-财富小程序、3-健康小程序、4-海银基金、 5-财富CRM、 6-海银移动、
  // const source_module = {1:'掌上海银APP',2: '财富小程序',3: '健康小程序',4: '海银基金', 5: '财富CRM', 6:'海银移动'};
  const source_module = { 1: 'APP', 2: '小程序', 3: '小程序', 4: '海银基金', 5: '财富CRM', 6: '海银移动', 7: "APP" };
  const channel = getStorageItem("channelCode");
  const userInfo = getStorageItem("MEMBER_USER_INFO")||{};
    console.log(event_name, {
      source_module: source_module[channel]||channel,
      member_level: userInfo.levelName||'',
      // module_name:'',
      // module_type:'',
      // button_name:'',
      ...properties,
    });
  sensors.track(event_name, {
    source_module: source_module[channel]||channel,
    member_level: userInfo.levelName||'',
    // module_name:'',
    // module_type:'',
    // button_name:'',
    ...properties,
  });
}
