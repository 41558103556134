export default [
    /* {
        path: '/member1',
        name: 'member',
        meta: {
            title: "入会认证",
        },
        component: resolve => require(['@/views/member/join/index'], resolve),
        redirect: { name: 'join_member_one' },
        children: [{
            path: 'join/one',
            name: 'join_member_one',
            meta: {
                title: "入会认证",
                statusColor: 'black',
                BU: 'VUE_APP_MEMBER'
            },
            component: resolve => require(['@/views/member/join/one'], resolve),
        }, {
            path: 'join/two',
            name: 'join_member_two',
            meta: {
                title: "入会认证",
                statusColor: 'black',
                BU: 'VUE_APP_MEMBER'
            },
            component: resolve => require(['@/views/member/join/two'], resolve),
        }, {
            path: 'face/result',
            name: 'member_baidu_face_result',
            meta: {
                title: "认证结果",
                statusColor: 'black',
                BU: 'VUE_APP_MEMBER'
            },
            component: resolve => require(['@/views/member/join/result_face'], resolve),
        }, {
            path: 'join/three',
            name: 'join_member_three',
            meta: {
                title: "入会认证",
                statusColor: 'black',
                BU: 'VUE_APP_MEMBER'
            },
            component: resolve => require(['@/views/member/join/three'], resolve),
        }, {
            path: 'join/sus',
            name: 'join_member_sus',
            meta: {
                title: "提交",
                statusColor: 'black',
                BU: 'VUE_APP_MEMBER'
            },
            component: resolve => require(['@/views/member/join/sus'], resolve),
        }, { // 企业
            path: 'join/enterprise/one',
            name: 'join_member_enterprise_one',
            meta: {
                title: "入会认证",
                statusColor: 'black',
                BU: 'VUE_APP_MEMBER'
            },
            component: resolve => require(['@/views/member/join/enterprise/one'], resolve),
        }, { // 企业
            path: 'join/enterprise/two',
            name: 'join_member_enterprise_two',
            meta: {
                title: "入会认证",
                statusColor: 'black',
                BU: 'VUE_APP_MEMBER'
            },
            component: resolve => require(['@/views/member/join/enterprise/two'], resolve),
        }, {
            path: 'join/enterprise/sus',
            name: 'join_member_enterprise_sus',
            meta: {
                title: "提交",
                statusColor: 'black',
                BU: 'VUE_APP_MEMBER'
            },
            component: resolve => require(['@/views/member/join/enterprise/sus'], resolve),
        }, {
            path: 'join/appeal',
            name: 'member_appeal',
            meta: {
                title: "账号申诉",
                statusColor: 'black',
                BU: 'VUE_APP_MEMBER'
            },
            component: resolve => require(['@/views/member/join/appeal'], resolve),
        }]
    },  */{
        path: '/member/join/one',
        name: 'join_member_one',
        meta: {
            title: "入会认证",
            statusColor: 'black',
            BU: 'VUE_APP_MEMBER'
        },
        // component: resolve => require(['@/views/member/join/one.vue'], resolve),
        component: resolve => require(['@/views/MemberV2/Join/Person/one.vue'], resolve),
    }, {
        path: '/member/join/two',
        name: 'join_member_two',
        meta: {
            title: "入会认证",
            statusColor: 'black',
            BU: 'VUE_APP_MEMBER'
        },
        component: resolve => require(['@/views/MemberV2/Join/Person/two.vue'], resolve),
    }, {
        path: '/member/join/three',
        name: 'join_member_three',
        meta: {
            title: "入会认证",
            statusColor: 'black',
            BU: 'VUE_APP_MEMBER'
        },
        component: resolve => require(['@/views/MemberV2/Join/Person/three.vue'], resolve),
    }, {
        path: '/member/join/sus',
        name: 'join_member_sus',
        meta: {
            title: "入会认证",
            statusColor: 'black',
            BU: 'VUE_APP_MEMBER'
        },
        component: resolve => require(['@/views/MemberV2/Join/Person/sus.vue'], resolve),
    }, {
        path: '/member/face/loading',
        name: 'member_baidu_face_loading',
        meta: {
            title: "人脸识别",
            statusColor: 'black',
            BU: 'VUE_APP_MEMBER'
        },
        component: resolve => require(['@/views/MemberV2/Join/Person/face_load'], resolve),
    }, {
        path: '/member/face/result',
        name: 'member_baidu_face_result',
        meta: {
            title: "认证结果",
            statusColor: 'black',
            BU: 'VUE_APP_MEMBER'
        },
        component: resolve => require(['@/views/MemberV2/Join/Person/result_face'], resolve),
    }, {
        //企业入会
        path: '/member/join/enterprise/one',
        name: 'join_member_enterprise_one',
        meta: {
            title: "入会认证",
            statusColor: 'black',
            BU: 'VUE_APP_MEMBER'
        },
        component: resolve => require(['@/views/MemberV2/Join/enterprise/one.vue'], resolve),
    }, {
        //企业入会
        path: '/member/join/enterprise/two',
        name: 'join_member_enterprise_two',
        meta: {
            title: "入会认证",
            statusColor: 'black',
            BU: 'VUE_APP_MEMBER'
        },
        component: resolve => require(['@/views/MemberV2/Join/enterprise/two.vue'], resolve),
    }, {
        path: '/member/join/enterprise/sus',
        name: 'join_member_enterprise_sus',
        meta: {
            title: "入会认证",
            statusColor: 'black',
            BU: 'VUE_APP_MEMBER'
        },
        component: resolve => require(['@/views/MemberV2/Join/enterprise/sus.vue'], resolve),
    }, {
        path: '/member/introduce',
        name: 'member_introduce',
        meta: {
            title: "会员详情",
            keepAlive: true,
            statusColor: 'white',
            BU: 'VUE_APP_MEMBER'
        },
        // component: resolve => require(['@/views/member/introduce'], resolve),
        component: resolve => require(['@/views/MemberV2/introduce'], resolve),
    }, {
        path: '/member/update_introduce',
        name: 'member_update_introduce',
        meta: {
            title: "会员详情",
            keepAlive: true,
            statusColor: 'white',
            BU: 'VUE_APP_MEMBER'
        },
        component: resolve => require(['@/views/member/update_introduce'], resolve),
    }, {
        path: '/member/enter_introduce',
        name: 'member_enter_introduce',
        meta: {
            title: "活动详情",
            keepAlive: true,
            statusColor: 'white',
            BU: 'VUE_APP_MEMBER'
        },
        component: resolve => require(['@/views/member/enter_introduce'], resolve),
    }, {
        //同意并授权
        path: '/member/empower',
        name: 'member_empower',
        meta: {
            title: "会员详情",
            statusColor: 'white',
            BU: 'VUE_APP_MEMBER'
        },
        component: resolve => require(['@/views/member/empower'], resolve),
    }, {
        path: '/member/detail',
        name: 'member_detail',
        meta: {
            title: "成长值明细",
            statusColor: 'black',
            BU: 'VUE_APP_MEMBER'
        },
        component: resolve => require(['@/views/member/detail'], resolve),
    }, {
        path: '/member/center',
        name: 'member_center',
        meta: {
            keepAlive: true,
            title: "HYW大会员",
            statusColor: 'white',
            BU: 'VUE_APP_MEMBER'
        },
        // component: resolve => require(['@/views/member/center'], resolve),
        component: resolve => require(['@/views/MemberV2/Center'], resolve),
    }, /* {
        path: '/member/center_old',
        name: 'member_center_old',
        meta: {
            keepAlive: true,
            title: "HYW大会员",
            statusColor: 'white',
            BU: 'VUE_APP_MEMBER'
        },
        component: resolve => require(['@/views/member/center'], resolve),
    }, */ {
        path: '/member/equity',
        name: 'member_equity',
        meta: {
            title: "HYW会员尊享",
            statusColor: 'black',
            BU: 'VUE_APP_MEMBER'
        },
        // component: resolve => require(['@/views/member/equity'], resolve),
        component: resolve => require(['@/views/MemberV2/Equity'], resolve),
    },
    /* {
        path: '/member/equity/levelRule',
        name: 'member_equity_levelRule',
        meta: {
            title: "HYW会员体系规则",
            statusColor: 'black',
            BU: 'VUE_APP_MEMBER'
        },
        component: resolve => require(['@/views/MemberV2/LevelRule/index'], resolve),
    }, */

    {
        path: '/member/equity/record',
        name: 'member_equity_record',
        meta: {
            title: "兑换记录",
            statusColor: 'black',
            BU: 'VUE_APP_MEMBER'
        },
        component: resolve => require(['@/views/member/equity_record'], resolve),
    }, {
        path: '/member/collect/agree',
        name: 'member_collect_agree',
        meta: {
            title: "协议详情", // 用户数据采集
            statusColor: 'black',
            BU: 'VUE_APP_MEMBER'
        },
        component: resolve => require(['@/views/MemberV2/static/collect_agree'], resolve),
        // component: resolve => require(['@/views/member/static/collect_agree'], resolve),
    }, {
        path: '/member/hyw/agree',
        name: 'member_hyw_agree',
        meta: {
            title: "协议详情", // HYW会员协议
            statusColor: 'black',
            BU: 'VUE_APP_MEMBER'
        },
        component: resolve => require(['@/views/MemberV2/static/hyw_agree'], resolve),
    }, {
        path: '/member/manual',
        name: 'member_manual',
        meta: {
            title: "HYW大会员体系等级规则",// 会员手册
            statusColor: 'black',
            BU: 'VUE_APP_MEMBER'
        },
        component: resolve => require(['@/views/MemberV2/LevelRule/index'], resolve),
        // component: resolve => require(['@/views/member/static/manual'], resolve),
    }, {
        path: '/member/update/skill',
        name: 'member_update_skill',
        meta: {
            title: "HYW星级会员体系升级宝典",
            statusColor: 'black',
            BU: 'VUE_APP_MEMBER'
        },
        component: resolve => require(['@/views/MemberV2/LevelRule/index'], resolve),
        // component: resolve => require(['@/views/member/static/skill'], resolve),
    }, /* {
        path: '/member/testimg',
        name: 'member_testimg',
        meta: {
            title: "testimg",
            statusColor: 'black',
            BU: 'VUE_APP_MEMBER'
        },
        component: resolve => require(['@/views/member/testimg'], resolve),
    }, */
    {
        path: '/member/memberShare',
        name: 'member_memberShare',
        meta: {
            title: "会员分享",
            statusColor: 'black',
            BU: 'VUE_APP_MEMBER'
        },
        component: resolve => require(['@/views/member/memberShare'], resolve),
    }
]