/*
 * @Author: liufeifei
 * @Date: 2022-10-17 19:25:28
 * @LastEditors: liufeifei 1076747468@qq.com
 * @LastEditTime: 2023-04-23 11:12:18
 * @Des:
 * @version:
 */
import { getStorageItem } from "@/cookie";
export function phoneFormat(val) {
  if (!val) return val;
  return val.slice(0, 3) + "****" + val.slice(-4);
}
export function phoneFormat_1(val) {
  if (!val) return val;
  return val.slice(0, 3) + " " + val.slice(3, 7) + " " + val.slice(7, 11);
}

/**
 * 检验18位身份证号码严格验证
 * @param idcard 18位的身份证号码
 **/
export function checkIdCard(idcard) {
  var Errors = new Array(false, "身份证格式不正确!");
  var reg = /^[0-9]{15}([0-9]{2}([0-9]|x|X))?$/;
  if (!reg.test(idcard)) {
    return Errors[1];
  }
  return Errors[0];
  /* var Errors = new Array(false, "身份证号码位数不对!", "身份证号码出生日期超出范围或含有非法字符!", "身份证号码校验错误!", "身份证地区非法!");
    var area = {
        11: "北京", 12: "天津", 13: "河北", 14: "山西", 15: "内蒙古", 21: "辽宁", 22: "吉林", 23: "黑龙江", 31: "上海", 32: "江苏", 33: "浙江", 34: "安徽", 35: "福建", 36: "江西", 37: "山东", 41: "河南", 42: "湖北", 43: "湖南", 44: "广东", 45: "广西", 46: "海南", 50: "重庆", 51: "四川", 52: "贵州", 53: "云南", 54: "西藏", 61: "陕西", 62: "甘肃", 63: "青海", 64: "宁夏", 65: "新疆", 71: "台湾", 81: "香港", 82: "澳门", 91: "国外"
    }
    var idcard, Y, JYM;
    var S, M;
    var idcard_array = new Array();
    idcard_array = idcard.split("");
    //地区检验
    if (area[parseInt(idcard.substr(0, 2))] == null) return Errors[4];
    var ereg;
    //身份号码位数及格式检验
    switch (idcard.length) {
        case 15:
            if ((parseInt(idcard.substr(6, 2)) + 1900) % 4 == 0 || ((parseInt(idcard.substr(6, 2)) + 1900) % 100 == 0 && (parseInt(idcard.substr(6, 2)) + 1900) % 4 == 0)) {
                ereg = /^[1-9][0-9]{5}[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}$/; //测试出生日期的合法性
            } else {
                ereg = /^[1-9][0-9]{5}[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|1[0-9]|2[0-8]))[0-9]{3}$/; //测试出生日期的合法性
            }
            if (ereg.test(idcard)) return Errors[0];
            else return Errors[2];
            break;
        case 18:
            //18位身份号码检测
            //出生日期的合法性检查
            //闰年月日:((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))
            //平年月日:((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|1[0-9]|2[0-8]))
            if (parseInt(idcard.substr(6, 4)) % 4 == 0 || (parseInt(idcard.substr(6, 4)) % 100 == 0 && parseInt(idcard.substr(6, 4)) % 4 == 0)) {
                // 19[0-9]{2}  20[0-9]{2}
                ereg = /^[1-9][0-9]{5}[0-9]{4}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}[0-9Xx]$/; //闰年出生日期的合法性正则表达式
            } else {
                ereg = /^[1-9][0-9]{5}[0-9]{4}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|1[0-9]|2[0-8]))[0-9]{3}[0-9Xx]$/; //平年出生日期的合法性正则表达式
            }
            console.log(idcard, ereg);
            if (ereg.test(idcard)) { //测试出生日期的合法性
                //计算校验位
                S = (parseInt(idcard_array[0]) + parseInt(idcard_array[10])) * 7 + (parseInt(idcard_array[1]) + parseInt(idcard_array[11])) * 9 + (parseInt(idcard_array[2]) + parseInt(idcard_array[12])) * 10 + (parseInt(idcard_array[3]) + parseInt(idcard_array[13])) * 5 + (parseInt(idcard_array[4]) + parseInt(idcard_array[14])) * 8 + (parseInt(idcard_array[5]) + parseInt(idcard_array[15])) * 4 + (parseInt(idcard_array[6]) + parseInt(idcard_array[16])) * 2 + parseInt(idcard_array[7]) * 1 + parseInt(idcard_array[8]) * 6 + parseInt(idcard_array[9]) * 3;
                Y = S % 11;
                M = "F";
                JYM = "10X98765432";
                M = JYM.substr(Y, 1); //判断校验位
                if (M == idcard_array[17]) return Errors[0]; //检测ID的校验位
                else return Errors[3];
            } else return Errors[2];
            break;
        default:
            return Errors[1];
            break;
    } */
}

export function getUrlParam(href) {
  if (href.indexOf("?") > -1) {
    let paramStr = href.split("?")[1];
    let paramArr = paramStr.split("&");
    let returnObj = {};
    for (let i = 0; i < paramArr.length; i++) {
      let paramItem = paramArr[i].split("=");
      returnObj[paramItem[0]] = paramItem[1];
    }
    return returnObj;
  } else {
    return {};
  }
}

// 根据渠道code 跳转 健康小程序机构详情 from 拼接 参数
//"channel": "1", // 1、掌上海银APP、2-财富小程序、3-健康小程序、4-海银基金、 5-财富CRM、 6-海银移动、
/* 
    掌上海银:zshy
    海银移动:hyyd
    财富小程序:wealthMin
*/
export function getFormCodeJk() {
  const channel = getStorageItem("channelCode");
  const jkFromCode = {
    1: "zshy",
    2: "wealthMin",
    3: "healthMin",
    4: "fundMin",
    5: "",
    6: "hyyd",
  };
  return jkFromCode[channel] || "";
}
