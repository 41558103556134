/*
 * @Des: 
 * @version: 
 * @Author: liufeifei
 * @Date: 2023-06-28 14:24:09
 * LastEditors: yuelanfenghua
 * LastEditTime: 2023-11-29 13:03:58
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { fetchGet, fetchPost, fetchAll } from './http.js';
import { Row, Col, Form, FormItem, Input, Select, Option, Button, Dialog, Message, Checkbox, InputNumber, Table, TableColumn, Tabs, TabPane } from 'element-ui';

import { Toast } from 'vant';

import { appShowLoading } from '@/util/util'
import sensorsInit, { registerPage, addSensorsTrack } from '@/util/sensors'


import './assets/svg'


// import Vant from 'vant';
import 'vant/lib/index.css';
import 'vant/lib/icon/local.css';
import '@/assets/css/style.less';
import '@/util/monitoring';

// 引入下拉刷新
// import VueScroller from 'vue-scroller';

// 神策埋点
Vue.prototype.$sensors = sensorsInit();
Vue.prototype.$sensorsRegisterPage = registerPage;
Vue.prototype.$addSensorsTrack = addSensorsTrack;

Vue.config.productionTip = false;
//将方法挂载到Vue原型上
Vue.prototype.fetchGet = fetchGet;
Vue.prototype.fetchPost = fetchPost;
Vue.prototype.fetchAll = fetchAll;
Vue.prototype.$message = Message;
Vue.prototype.$EventBus = new Vue();

// 加载中
Vue.prototype.$showLoading = (msg = '加载中...') => {
  Toast.loading({
    message: msg,
    forbidClick: true,
    duration: 0,
  });
  appShowLoading(null, 0);
};
Vue.prototype.$hideLoading = () => {
  Toast.clear();
  appShowLoading(null, 0);
};

// 大会员体系 静态访问路径
Vue.prototype.MEMBER_STATIC_URL = process.env.VUE_APP_MEMBER_STATIC_URL;
Vue.prototype.PUBLIC_PATH = process.env.VUE_APP_PUBLICPATH;

Vue.use(Button);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Select);
Vue.use(Option);
Vue.use(Row);
Vue.use(Col);
Vue.use(Dialog);
Vue.use(Checkbox);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Tabs);
Vue.use(TabPane);
// Vue.use(VueScroller)
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
