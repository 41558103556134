import Vue from "vue";
import Vuex from "vuex";
import HeaderConfig from '@/store/module/headerConfig'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // 渠道
    CHANNEL_CODE: '',
  },
  getters: {},
  mutations: {
    SET_CHANNEL_CODE: (state, code) => {
      state.CHANNEL_CODE = code
    },
  },
  actions: {},
  modules: {
    HEADER: HeaderConfig,
  }
});
