import Vue from "vue";
import store from "@/store/index";
import VueRouter from "vue-router";

import { getStorageItem, setStorageItem } from '@/cookie'
import { setStatusBarStyle, setNavBarStatus, getStatusHeight } from '@/util/util'

import jf from './jf'
import member from './member'
/** meta 参数简介
 * safeBottom 是否预留 安全底部 iphone X 以上 默认 预留  false：不预留
 * keepAlive 是否保留 缓存
 * header 是否显示头部
 *  */
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, resolve, reject) {
  if (resolve || reject) return originalPush.call(this, location, resolve, reject)
  return originalPush.call(this, location).catch((e) => { })
}

Vue.use(VueRouter);


const routes = [].concat(jf, member, [
  // 测试人脸
  {
    path: '/testface',
    name: 'testface',
    component: resolve => require(['@/views/test/testface.vue'], resolve),
    meta: {
      title: "海银健康管家",
      show: false,
      keepAlive: true
    }
  },
  //404
  {
    path: '/404',
    name: '404',
    component: resolve => require(['@/views/404/index.vue'], resolve),
    meta: {
      title: "404",
      show: false,
      keepAlive: true
    }
  },
  {
    path: '*',
    name: '*',
    redirect: '/404'
  }
]);
const BUList = {
  VUE_APP_HEALTHY: process.env.VUE_APP_HEALTHY,
  VUE_APP_HYCF: process.env.VUE_APP_HYCF,
  VUE_APP_MEMBER: process.env.VUE_APP_MEMBER
}

const router = new VueRouter({
  routes,
});
router.beforeEach((to, from, next) => {
  // console.log('BUList', BUList, to.meta.BU, window.location)
  // try {
  //   if (to.meta.BU) {
  //     let bu = BUList[to.meta.BU];
  //     if (bu) {
  //       bu = bu.split(',')
  //       bu.push('localhost')
  //     }
  //     const hostname = window.location.hostname
  //     if (bu.indexOf(hostname) < 0) {
  //       next('/404')
  //       return
  //     }
  //   }
  // } catch (e) {
  //   console.log(e)
  // }

  // 更改app 状态颜色
  if (to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = ''
  }



  if (to.matched.length !== 0) {

    next()
  } else {
    next('/404')
  }
})
router.afterEach((to, from) => {
  if (to.meta.statusColor) {
    setStatusBarStyle(null, to.meta.statusColor)
  }
})
export default router;
