import Tingyun from "@tingyun-sdk/web";

if(process.env.NODE_ENV == 'production') {
    Tingyun.init(
      {
      "domain":"wkbrs2.tingyun.com",
      "token":"dcc4f583a99042f39992d410b3aee4d5",
      "key":"wO4aJOQK8X8",
      "id":"mJ_TxJELLuY",
      "page":{
        "drThreshold":5000,
        "fpThreshold":5000,
        "fsThreshold":5000,
        "leThreshold":5000,
        "pageLoadDelay":100
      },
      "operation":{
        "rageClickEnabled":false
      },
      "replay":{
        "sampleRate":0.1
      }
    }
    )
  }