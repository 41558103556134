const Herder = {
    namespaced: true,
    actions: {
        toggleShow({ commit }, { show }) {
            commit('SET_SHOW', show)
        }
    },
    mutations: {
        SET_SHOW: (state, show) => {
            state.show = show
        },
        SET_STATUS_HEIGHT: (state, height, update) => {
            state.statusHeight = height
            state.isUpdateStatusHeight = update
        }
    },
    state: {
        show: true,
        statusHeight: 20,
        isUpdateStatusHeight: false, // 是否已更新状态栏
    }
}
export default Herder