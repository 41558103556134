export const getUrlParam = (name) => {
    //构造一个含有目标参数的表达式正则表达式对象
    let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
    // // 匹配目标参数
    // let r = window.location.search.substr(1).match(reg);
    // if (r != null) return unescape(r[2])
    // return null
    // console.log('4423432',window.location.search)
    let search = window.location.search.substr(1)
    if (!search) {
        search = window.location.hash.split('?')[1]
    }

    if (search) {
        // let obj = JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
        // return name ? obj[name] : obj
        let r = search.match(reg);
        if (r != null) return unescape(r[2])
        return null
    }
}