export default [
    {
        path: '/',
        name: 'WechatEntry',
        component: resolve => require(['@/views/EntryPage/WechatEntry/Index.vue'], resolve),
        meta: {
            title: "海银健康管家",
            show: false,
            keepAlive: true,
            BU: 'VUE_APP_HEALTHY'
        }
    },


    {
        path: '/wechatPhone',
        name: 'WechatPhone',
        component: resolve => require(['@/views/WechatPhone/Index.vue'], resolve),
        meta: {
            title: "海银健康管家",
            show: false,
            keepAlive: true,
            BU: 'VUE_APP_HEALTHY'
        }
    },

    // 新掌上海银进入页
    {
        path: '/newApplicationEntry',
        name: 'NewApplicationEntry',
        component: resolve => require(['@/views/EntryPage/NewApplicationEntry/Index.vue'], resolve),
        meta: {
            title: "积分互兑",
            show: false,
            keepAlive: true,
            BU: 'VUE_APP_HYCF'
        }
    },
    // 新掌上海银
    {
        path: '/newExchange',
        name: 'NewExchange',
        component: resolve => require(['@/views/NewExchange/Index.vue'], resolve),
        meta: {
            title: "积分互兑",
            show: false,
            BU: 'VUE_APP_HYCF'
        }
    },
    // 旧掌上海银进入页
    {
        path: '/applicationEntry',
        name: 'ApplicationEntry',
        component: resolve => require(['@/views/EntryPage/ApplicationEntry/Index.vue'], resolve),
        meta: {
            title: "积分互兑",
            show: false,
            keepAlive: true,
            BU: 'VUE_APP_HYCF'
            
        }
    },
    // 旧掌上海银
    {
        path: '/exchange',
        name: 'Index',
        component: resolve => require(['@/views/Home/Index.vue'], resolve),
        meta: {
            title: "积分互兑",
            show: false,
            BU: 'VUE_APP_HYCF'
        }
    },
    // 健康小程序
    {
        path: '/jkWechatEntry',
        name: 'JKWechatEntry',
        component: resolve => require(['@/views/EntryPage/JKWechatEntry/Index.vue'], resolve),
        meta: {
            title: "海银健康管家",
            show: false,
            keepAlive: true,
            BU: 'VUE_APP_HEALTHY'
        }
    },
    // 健康小程序
    {
        path: '/newJKWechatExchange',
        name: 'NewJKWechatExchange',
        component: resolve => require(['@/views/NewJKWechatExchange/Index.vue'], resolve),
        meta: {
            title: "积分互兑",
            show: false,
            BU: 'VUE_APP_HEALTHY'
        }
    },

    {
        path: '/rule',
        name: 'Rule',
        component: resolve => require(['@/views/Rule/Index.vue'], resolve),
        meta: {
            title: "规则说明",
            show: false
        }
    },
    {
        path: '/dataRule',
        name: 'DataRule',
        component: resolve => require(['@/views/DataRule/Index.vue'], resolve),
        meta: {
            title: "用户数据协议",
            show: false
        }
    },
    //验证码页面 
    {
        path: '/verityCode',
        name: 'VerityCode',
        component: resolve => require(['@/views/VerityCode/Index.vue'], resolve),
        meta: {
            title: "海银健康管家",
            show: false,
            keepAlive: true,
            BU: 'VUE_APP_HEALTHY'
        }
    },
    //登陆失败回调页面 
    {
        path: '/failureCallback',
        name: 'FailureCallback',
        component: resolve => require(['@/views/FailureCallback/Index.vue'], resolve),
        meta: {
            title: "海银健康管家",
            show: false,
            BU: 'VUE_APP_HEALTHY'
        }
    },
    {
        path: '/customerRule',
        name: 'CustomerRule',
        component: resolve => require(['@/views/CustomerRule/Index.vue'], resolve),
        meta: {
            title: "用户协议",
            show: false
        }
    },


    {
        path: '/myOrderEntry',
        name: 'MyOrderEntry',
        component: resolve => require(['@/views/EntryPage/MyOrderEntry/Index.vue'], resolve),
        meta: {
            title: "我的订单",
            keepAlive: true,
            show: false,
            safeBottom: true
        }
    },
    {
        path: '/myOrderDetail',
        name: 'myOrderDetail',
        component: resolve => require(['@/views/EntryPage/myOrderDetail/Index.vue'], resolve),
        meta: {
            title: "订单详情",
            show: false,
            safeBottom: true,
            BU: 'VUE_APP_HYCF'
        }
    },
    {
        path: '/myOrderLogistics',
        name: 'myOrderLogistics',
        component: resolve => require(['@/views/EntryPage/myOrderLogistics/Index.vue'], resolve),
        meta: {
            title: "物流信息",
            show: false,
            safeBottom: true,
            BU: 'VUE_APP_HYCF'
        }
    },
    {
        path: '/loading',
        name: 'Loading',
        component: resolve => require(['@/views/Loading/Index.vue'], resolve),
        meta: {
            title: "海银健康管家",
            show: false,
            BU: 'VUE_APP_HEALTHY,VUE_APP_HYCF'
        }
    },
]