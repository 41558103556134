/*
 * @Des: 
 * @version: 
 * @Author: liufeifei
 * @Date: 2023-05-04 09:23:22
 * LastEditors: yuelanfenghua
 * LastEditTime: 2023-12-13 10:55:00
 */

// 权益详情跳转 小程序 来源配置
// 小程序登录页  个人信息页 配置  jump  1 switchTab  2 reLaunch    navigateTo
export const wechatConfig = [{
    code: '2', // 财富小程序
    appId: 'wxabbb424cb32d5559', // gh_3d48eca54475
    originalId: 'gh_3d48eca54475',
    title: '海银财富Lite',
    loginPage: '/pages/login/login', // 登录页
    personPage: '/pages/user/user', // 个人信息页
    appDownPage: '/page_other/pages/download/download', // 掌上海银app下载页
}, {
    code: '3', // 健康小程序
    appId: 'wxd26878b0e01f55a9', // gh_628640c46563
    originalId: 'gh_628640c46563',
    title: '海银健康管家',
    loginPage: '/pages/login/login', // 登录页
    personPage: '/pages/user/user', // 个人信息页
    appDownPage: '/page_other/pages/download/download', // 掌上海银app下载页
}, {
    code: '4', // 海银基金小程序
    appId: 'wx0175042ddbe86fcc', // gh_b5323b43a183
    originalId: 'gh_b5323b43a183', // 
    title: '基金小程序',
    loginPage: '/pages/login/login', // 登录页
    personPage: '/pages/user/user', // 个人信息页
}, {  // 商品小程序
    appId: 'wx5724e8b4e6e125b6', // gh_c5edb2fb109d
    originalId: 'gh_c5edb2fb109d', // 
    title: '海银健管商品',
}, {  // 贵酒小程序
    code: '8', // 贵酒小程序
    appId: 'wxc8a4966e81ed5d22', // gh_c5edb2fb109d
    originalId: 'gh_4b8e933a26d7', // 
    title: '上海贵酒股份会员中心',
    loginPage: '/pages/login/login', // 登录页

}];


/* 
h5跳转原生我的页面   jumpMinePage()
// h5跳转新的h5贝面 jumpNewWebPage()

任务一：用户在掌上海银完成风险测评   跳转至风险测评页面 https://zshy.hywinwealth.com/hy-zshy-h5/#/risk?from=HYW
任务二：用户在掌上海银完成电子签—    跳转合同签署列表页 https://zshy.hywinwealth.com/hy-zshy-h5/#/contract-sign?from=HYW
 任务六：用户在掌上海银查阅投后报告   跳转至我的页面 
 
 补全信息  https://test.hywinwealth.com/hy-zshy-h5/#/user-detail

掌上海银电子签列表页面	https://zshy.hywinwealth.com/hy-zshy-h5/#/contract-sign?from=HYW	测试域名：https://test.hywinwealth.com
问卷列表页	https://zshy.hywinwealth.com/hy-zshy-h5/#/risk?from=HYW	
投后文件	 https://zshy.hywinwealth.com/hy-zshy-h5/#/private-files?type=3	
小程序活动列表	pages/discover/discover?tab=4	
小程序直播列表	pages/discover/discover?tab=1	

*/